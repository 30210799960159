/* === FONTS *font-xxx* === */
$font-header:               "Playfair Display", sans-serif;
$font-body:                 "sen", sans-serif;
$font-caps:                 $font-header;
$font-cursive:              "Caveat", cursive, sans-serif;

$font-xxxl:                 3.75rem;
$font-xxl:                  2.5rem;
$font-xl:                   2rem;
$font-large:                1.75rem;
$font-medium:               1.3rem;
$font-small:                1rem;
$font-xs:                   0.875rem;
$font-xxs:                  0.75rem;
$regular-weight:            400;
$bold-weight:               700;
$bolder-weight:             600;
$font-clamp-vw:             3.5vw;

$clr-primary:               #4F5547;
$clr-primary-alt:           #3C4235;
$clr-secondary:             #6F5D49;
$clr-secondary-alt:         #B69979;
$clr-third:                 #C29135;
$clr-third-alt:             #D8B27B;

$clr-success:               #00be0a;
$clr-error:                 #BA1A1A;
$clr-error-container:       #FFDAD6;
$clr-warning:               #ec942c;

$clr-link:                  #EFBF63;
$clr-text:                  #252525;
$clr-dark:                  #010101;
$clr-shade:                 #E6DDD6;
$clr-white:                 #FFFFFF;
$clr-border-shade:          rgba($clr-white, 0.25);

/* === MISC. === */
$card-shadow:               0 0 20px rgba($clr-dark, 0.0425);
$border-radius:             4px;
$border-radius-large:       8px;
$button-radius:             .375rem;
$gutter:                    clamp(1.5rem, 1.5vw, 3rem);
$narrow-inner:              74rem;
$spacing_vw:                5.5vw;
$padding:                   clamp(2rem, 3.5vw, 4rem);
$padding-large:             clamp(3rem, 3.5vw, 5rem);
$mobile_brkpt:              1260px;
$wave-padding-bottom:       clamp(4.5rem, 10vw, 9rem);