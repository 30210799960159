@import 'variables';
@import 'mixins';
@import 'components/splide';
@import 'components/faq';

/*------------------------------------*\
    #ADMIN CONTENT
\*------------------------------------*/

// .image-wrapper {
//   background-color: $clr-shade;
//   padding-top: 55.25%;
//   position: relative;

//   img {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     height: auto;
//     transform: translate(-50%,-50%);
//     width: auto;
//   }
// }

.admin-content {
  @include flex-direction(column);
  gap: 1rem;
  
  h2, h3, h4, h5, h6, p, ul, ol {
      margin: 0;
  }

  img {
      margin-bottom: 1rem;
      max-height: 480px;
      max-width: 100%;
      object-fit: contain;
      background-color: $clr-shade;
  }

  p img {
      margin-bottom: 0;
  }

  blockquote {
      border-left: solid 2px $clr-shade;
      display: flex;
      flex-direction: column;
      gap: 1em;
      margin-left: clamp(0.5rem, 3.5vw, 1rem);
      padding-left: clamp(1rem, 3.5vw, 2rem);
      position: relative;

      &::before {
          content: '"';
          font-family: $font-cursive;
          top: 1rem;
          left: 0;
          height: 1.5rem;
          width: 1.5rem;
          padding: 0.25rem 0.75rem 0.75rem 0.25rem;
          border: solid 2px $clr-shade;
          background-color: $clr-white;
          position: absolute;
          border-radius: 100%;
          text-align: center;
          font-weight: $bold-weight;
          transform: translate(-50%);
          line-height: 1;
          @include font-xxl;
      }
  }
}

/*------------------------------------*\
    #FORM MESSAGES
\*------------------------------------*/

.form__message {
  @include flex-direction(row);
  align-items: center;
  background: $clr-dark;
  color: $clr-white;
  gap: clamp(0.25rem, 3.5vw, 0.5rem);
  justify-content: center;
  margin-bottom: clamp(0.5rem, 3.5vw, 1rem);
  padding: clamp(0.5rem, 3.5vw, 1rem);
  text-align: center;

  a {
    color: $clr-white;
    @include link-underline($clr-white);
  }

  &--success {
    background: $clr-success;
  }

  &--error {
    background: $clr-error;
  }
}

/*------------------------------------*\
    #FORM CHECKBOX
\*------------------------------------*/

.form__checkbox-button {
  display: block;
  position: relative;
  padding-left: 2.25rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form__checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form__checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2px rgba($clr-dark, .5) solid;
}

.form__checkbox-button:hover input~.form__checkbox-checkmark {
  background-color: #ccc;
  border-color: $clr-dark;
}

.form__checkbox-button input:checked~.form__checkbox-checkmark {
  background-color: $clr-success;
  border-color: $clr-success;
}

.form__checkbox-button input:focus-visible~.form__checkbox-checkmark {
  outline: 3px solid #fd0;
  outline-offset: 0;
  box-shadow: inset 0 0 0 2px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.form__checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.form__checkbox-button input:checked~.form__checkbox-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.form__checkbox-button .form__checkbox-checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*------------------------------------*\
    #FORM SELECT
\*------------------------------------*/

.form__select {
  display: block;
  line-height: 1.3;
  padding: .575em 2em .575em .75em;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, .04);
  border-radius: $border-radius;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('/images/direction_down.svg');
  background-repeat: no-repeat;
  background-position: right .675rem top 50%;
  background-size: .75rem auto;
  cursor: pointer;
  font-family: $font-body;

  &:focus-visible {
    outline: 3px solid #fd0;
    outline-offset: 0;
    box-shadow: inset 0 0 0 2px;
  }
}

.form__select::-ms-expand {
  display: none;
}

.form__select:hover {
  border-color: $clr-dark;
}

.form__select option {
  font-weight: normal;
}

/*------------------------------------*\
    #FORM RADIO
\*------------------------------------*/

.form__radio-input {
  display: none;
}

.form__radio {
  margin-top: 1rem;
  position: relative;
  padding-left: 2rem;
}

.form__radio-label {
  display: block;
  cursor: pointer;

  &:hover {
    @include link-underline(black);
  }
}

.form__radio-button {
  height: 1.5rem;
  width: 1.5rem;
  border: 2px solid $clr-dark;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.form__radio-button::after {
  content: "";
  display: block;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px $clr-dark solid;
  background-color: #fff;
  opacity: 1;
  transition: opacity 0.2s;
}

.form__radio-input:checked+.form__radio-button {
  border-color: $clr-primary;
}

.form__radio-input:checked+.form__radio-button::after {
  border-color: $clr-primary;
  background-color: $clr-primary;
  opacity: 1;
}

.form__radio-input:focus+.form__radio-button::after,
.form__radio-input:focus-visible+.form__radio-button::after {
  outline: 3px solid #fd0;
  outline-offset: 6px;
}

.form__radio-input:focus-visible~strong {
  @include link-underline(black);
}

/*------------------------------------*\
    #FORM INPUTS
\*------------------------------------*/

.form__radio-input {
  display: none;
}

.form__radio-label {
  display: block;
  cursor: pointer;

  &:hover {
    @include link-underline(black);
  }
}

/*------------------------------------*\
    #CALCULATOR
\*------------------------------------*/

@import 'components/claim-form';

.calculator {
  background-color: $clr-white;
  border: solid 1px $clr-shade;
  border-radius: $border-radius;
  margin-top: $padding;
  padding: clamp(2rem, 3.5vw, 1rem);
}
.calculator__action {
  @include flex-direction(row);
  justify-content: center;
  margin-top: $padding;
}

/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.footer {
  // background-color: $clr-primary;
  // background-image: url('/images/static/footer-background.jpg');
  // background-size: cover;
  color: rgba($clr-white, 0.75);
  padding-top: $padding-large;
  position: relative;

  &::before {
    background: linear-gradient(to bottom, $clr-primary, rgba($clr-primary, 0.9));
    content: '';
    position: absolute;
    inset: 0;
    z-index: 1;
  }
}
.footer__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.footer__overlay {
  position: relative;
  z-index: 1;
}
.footer__title {
  color: $clr-white;
}

// SELL WITH CONFIDENCE

.sell-with-confidence {
  @include grid(1fr 1fr);
  border-bottom: solid 1px rgba($clr-white, 0.125);
  gap: $gutter;
  margin-bottom: $padding;
  padding-bottom: $padding;
}
.sell-with-confidence__col {
  @include flex-direction(row);
  align-items: center;
  gap: 1.5rem;
}
.sell-with-confidence__title {
  color: $clr-white;
}
.sell-with-confidence__logo {
  flex: 140px 0;
}
.sell-with-confidence__logo-image {
  height: 120px;
  width: auto;
}
.sell-with-confidence__content {
  flex: 1 0;
}
.sell-with-confidence__text {
  @include font-small;
}

// FOOTER CONTAINER

.footer__container {
  @include grid(repeat(4, 1fr));
  gap: $gutter;
}
.footer__col--explore {
  grid-column: 3/5;
}
.footer__text {
  margin-top: 0.75rem;

  &--icon {
    @include flex-direction(row);
    align-items: flex-start;
    gap: clamp(0.25rem, 1.5vw, 0.5rem);

    svg {
      color: $clr-third;
      margin-top: 4px;
      height: 20px;
      width: 20px;
    }
  }
}
.footer__link {
  color: $clr-white;
  font-weight: 100;
  text-decoration-color: rgba($clr-white, 0.25);

  &:hover {
    color: $clr-white;
    text-decoration-color: $clr-white;
  }
  
  &:active,
  &:focus,
  &:focus-within {
    outline: 0;
    text-decoration-color: $clr-white;
  }
}
.footer__nav {
  &--split {
    column-count: 2;
    column-gap: 2rem;
  }
}
.footer__nav-item:not(:first-child) {
  margin-top: 0.75rem;
}

// COPYRIGHT

.copyright {
  margin-top: $padding;
}
.copyright__inner {
  @include flex-direction(row);
  border-top: solid 1px rgba($clr-white, 0.125);
  gap: $gutter;
  padding: 1.5rem 0;
}
.copyright__text {
  @include font-small;
  flex: 1 0;
  margin: 0;
}

// SOCIAL MEDIA

.socials {
  @include flex-direction(row);
  flex-wrap: wrap;
  align-items: center;
  gap: clamp(0.75rem, 1.5vw, 1.25rem);
  justify-content: flex-end;
}
.external-social {
  @include flex-direction(row);
  @include font-medium;
  align-items: center;
  border: solid 1px rgba($clr-link, 0.3);
  border-radius: 100%;
  color: $clr-third;
  justify-content: center;
  height: 44px;
  width: 44px;
  transition: border-color 350ms ease-in-out;

  &:hover {
    border-color: $clr-link;
    color: $clr-link;
  }

  &:focus,
  &:focus-visible {
    @include accessible-focus(solid, $clr-link, 2px, 2px);
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

/*------------------------------------*\
    #RELATED NEWS
\*------------------------------------*/

.section--related-news {
  padding: 0 0 $padding-large;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: $padding-large;
    background-color: $clr-shade;
  }
}

.related-news {
  @include grid(repeat(3, 1fr));
  align-items: flex-end;
  gap: $gutter;
}
.related-news__content {
  @include flex-direction(column);
  background-color: $clr-white;
  border: solid 1px $clr-shade;
  grid-column: 1/2;
  justify-content: space-between;
  min-height: 490px;
  height: 100%;
}
.related-news__wrap {
  padding: $gutter $gutter 0;
}
.related-news__carousel {
  grid-column: 2/4;
}
.related-news .splide__slide {
  padding: 0;
}

.newsletter {
  background-color: rgba($clr-shade, 0.3);
  padding: $gutter;
}
.newsletter__heading {
  @include flex-direction(row);
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  svg {
    height: 38px;
    width: 38px;
  }
}
.newsletter__title {
  text-align: center;
}
.newsletter__input {
  border: solid 1px $clr-dark;
  border-radius: 30px;
  height: 60px;
  position: relative;
}
.newsletter__input-field {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 30px !important;
  height: 60px;
  padding: 1em 40% 1em 1.5em !important;
  width: 100% !important;
}
.newsletter__input-button {
  background-color: $clr-secondary;
  border: solid 3px transparent;
  border-radius: 30px;
  color: $clr-white;
  font-weight: $bold-weight;
  height: 52px;
  padding: 0 1.25em;
  position: absolute;
  line-height: 1;
  top: 3px;
  right: 3px;
}

// ARTICLE LISTING (STANDARD)

.articles {
  @include grid(repeat(auto-fit, minmax(220px, 1fr)));
  gap: $gutter;
}

.article-listing {
  @include flex-direction(column);
  background-color: $clr-white;

  &--shade {
    background-color: #efefef;
  }
}
.article-listing__thumbnail {
  background-color: darken($clr-shade, 5%);
  display: block;
  padding-top: 70%;
  position: relative;
}
.article-listing__thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.article-listing__content {
  flex: 1 0;
  padding: 1.25rem;
}
.article-listing__date {
  @include font-small;
  margin-bottom: 0.5rem;
}
.article-listing__title {
  margin: 0;
  
  a {
    text-decoration-color: rgba($clr-secondary, 0.3);

    &:hover {
      text-decoration-color: rgba($clr-secondary, 0.6);
    }
  }
}
.article-listing__action {
  padding: 0 1.25rem 1.25rem;
}

/*------------------------------------*\
    #REASONS
\*------------------------------------*/

.reasons {
   background-color: $clr-primary-alt;
   padding: 2rem 0;
   position: relative;
}
.reasons__item {
  border-left: solid 1px $clr-primary;
  padding-left: 5%;
}
.reasons__item__title {
  @include flex-direction(row);
  align-items: center;
  color: $clr-white;
  gap: clamp(0.25rem, 1.5vw, 0.75rem);

  svg {
    color: $clr-third;
    height: 28px;
    width: 28px;
  }
}
.reasons__item__text {
  @include font-small;
  color: rgba($clr-white,  0.75);
  margin-top: 1rem;
}

/*------------------------------------*\
    #COOKIE ACCEPTANCE NOTICE
\*------------------------------------*/

#cookie-notice {
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 90%;
  max-width: 320px;
  background: rgba($clr-dark, .95);
  color: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.125);
  padding: 2rem 1.5rem;
  z-index: 1000;
  font-size: .875rem;

  &.hidden {
    opacity: 0;
    transition: opacity 0.75s linear;
    visibility: hidden;
  }
}

#cookie-notice a {
  color: #fff;
  text-decoration: underline;
}

#cookie-acceptance-button,
#cookie-decline-button {
  border: 0;
  padding: 0;
  background: transparent;
  color: #fff;
  border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: .875rem;
}

/*------------------------------------*\
    #MASTHEAD
\*------------------------------------*/

.masthead {
  background-color: $clr-shade;
  padding-top: $padding;

  &--background {
    overflow: hidden;
    padding: 0;
    position: relative;
  }
}
.masthead__header-image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  aspect-ratio: 1980 / 800;
  height: 100%;
  width: 100%;
  display: block;
}
.masthead__overlay {
  padding: 12rem 0;
  padding: clamp(6rem, 3.5vw, 12rem) 0;
  position: relative;
  z-index: 1;

  &--dark {
    background: linear-gradient(to right, $clr-primary, rgba($clr-primary, 0.5));
  }
}
.masthead__intro {
  @include flex-direction(column);
  align-items: flex-start;
  color: $clr-white;
  gap: 1.25rem;
  max-width: 600px;
}
.masthead__intro__content {
  padding: $padding 0;
}
.masthead__intro__caption {
  font-weight: $bold-weight;
}
.masthead__overlay--transparent .masthead__intro__caption {
  text-shadow: 0 0 10px $clr-text;
}
.masthead__intro__title {
  color: inherit;
  margin: 0;
}
.masthead__overlay--transparent .masthead__intro__title {
  text-shadow: 0 0 10px $clr-text;
}
.masthead__overlay--transparent .masthead__intro__tagline {
  text-shadow: 0 0 10px $clr-text;
}

// PLAIN MASTHEAD

.plain-masthead {
  background-color: darken($clr-shade, 5%);
  padding: clamp(2rem, 3.5vw, 4rem) 0;
}
.plain-masthead__content {
  @include flex-direction(column);
  gap: 1rem;
  text-align: center;
}
.plain-masthead__caption {
  font-family: $font-body;
  font-size: 1em;
  font-weight: $bold-weight;
}
.plain-masthead__title {
  margin: 0;
}
.plain-masthead__tagline {
  margin: 0 auto;
  max-width: 768px;
}

/*------------------------------------*\
    #PAGE PORTALS
\*------------------------------------*/

.section--page-portals {
  padding-top: $padding;
}

.page-portals {
  @include grid(repeat(auto-fit, minmax(250px, 1fr)));
  gap: $gutter;
}

.page-card {
  background-color: #6f5d49;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='1' viewBox='0 0 40 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h20v1H0z' fill='%23574939' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 3%;
  overflow: hidden;
  position: relative;
}
.page-card__link {
  display: block;
  height: 100%;
  text-decoration: none;

  &:active,
  &:focus,
  &:focus-within {
    .button {
      @include accessible-focus(solid, $clr-secondary, 2px, 2px);
    }
  }
}
.page-card__content {
  @include flex-direction(column);
  gap: clamp(9rem, 1.5vw, 12rem);
  justify-content: space-between;
  padding: clamp(1.5rem, 1.5vw, 2.5rem);
  position: relative;
  width: 65%;
  z-index: 1;
}
.page-card__text {
  @include flex-direction(column);
  gap: 1rem;
}
.page-card__thumbnail-image {
  position: absolute;
  right: -1px;
  bottom: -1px;
}
.page-card__title {
  color: $clr-white;
  margin: 0;
}

// PAGE PORTAL (ALT)

.page-portals--alt {
  @include grid(repeat(auto-fit, minmax(320px, 1fr)));
}

.page-portal {
  @include flex-direction(column);
  gap: 1rem;
}
.page-portal__thumbnail {
  background-color: darken($clr-white, 10%);
  display: block;
  padding-top: 60%;
  position: relative;

  &:focus,
  &:focus-within {
    @include accessible-focus(solid, $clr-link, 2px, 2px);
  }
}
.page-portal__thumbnail-image {
  @include absolute-fill;
  object-fit: cover;
}
.page-portal__title {
  margin: 0;

  a {
    &:focus,
    &:focus-within {
      @include accessible-focus(solid, $clr-link, 2px, 2px);
    }
  }
}

/*------------------------------------*\
    #PAGE VIDEO
\*------------------------------------*/

.page-video {
  background-color: $clr-shade;
  border: solid 1px $clr-shade;
  padding-top: 56.4%;
  position: relative;
  width: 100%;

  iframe {
    @include absolute-fill;
  }
}
.page-video__overlay {
  @include flex-direction(column);
  color: $clr-white;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &::before {
    background-color: $clr-primary;
    opacity: 0.9;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
}
.page-video__background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.play-video__button {
  @include flex-direction(row);
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  background-color: $clr-third;
  color: $clr-white;
  position: relative;

  svg {
    height: 24px;
    width: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.play-video__action {
  @include flex-direction(column);
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 3;
}

/*------------------------------------*\
    #TOOLS
\*------------------------------------*/

.section--tools {
  background-color: $clr-primary;
  overflow-x: hidden;
  padding: $padding-large 0 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0%;
    height: 16%;
    background-color: $clr-white;
    z-index: 2;
  }
}
.section--nofaqs {
  &::before {
    background-color: $clr-shade;
  }
}

.tools {
  position: relative;
}
.tools__content {
  color: $clr-white;
  max-width: 768px;
}
.tools__keyword {
  @include font-small;
  color: inherit;
  font-family: $font-body;
  line-height: 1;
  margin: 0 0 1rem;
  padding-left: 5%;
  padding-left: 3vw;
  position: relative;

  &::before {
    border-bottom: solid 1px rgba($clr-white, 0.45);
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 4%;
    width: 2vw;
  }
}
.tools__title {
  @include font-xl;
  color: $clr-white;
  font-family: $font-header;
  margin-bottom: 0;
}
.tools__text {
  color: inherit;
  margin-top: 1.5rem;
}
.tools__container {
  @include grid(repeat(6, 1fr));
  gap: $gutter;
  margin-top: $padding;
  position: relative;
  z-index: 3;
}

// TOOL

.tool-card {
  @extend .page-card;
  padding: 0;
  
  &:nth-child(1) {
    grid-column: 1/4;
  }
  &:nth-child(2) {
    grid-column: 4/7;
  }
  &:nth-child(3) {
    grid-column: 1/3;
  }
  &:nth-child(4) {
    grid-column: 3/5;
  }
  &:nth-child(5) {
    grid-column: 5/7;
  }
}
.tool-card__link {
  @extend .page-card__link;

  &:active,
  &:focus,
  &:focus-within {
    .tool-card__button {
      @include accessible-focus(solid, $clr-link, 2px, 2px);
    }
  }
}
.tool-card__content {
  @extend .page-card__content;
  gap: 2rem;
  height: 100%;
}
.tool-card:nth-child(1) .tool-card__content,
.tool-card:nth-child(2) .tool-card__content {
  gap: 6rem;
}
.tool-card__text {
  @extend .page-card__text;
}
.tool-card__thumbnail-image {
  @extend .page-card__thumbnail-image;
}
.tool-card__title {
  @extend .page-card__title;
}
.tool-card__tagline {
  color: #fff;
  font-weight: 100;
}
.tool-card__button {
  @include flex-direction(row);
  align-items: center;
  border: solid 1px $clr-link;
  border-radius: 30px;
  color: $clr-link;
  height: 60px;
  justify-content: center;
  outline-color: transparent;
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: width 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860), color 350ms ease-in-out;
  width: 60px;

  &::before {
    background-color: $clr-link;
    content: '';
    position: absolute;
    inset: 0;
    transform: scale(0);
    transition: transform 250ms ease-in-out;
    border-radius: 30px;
  }

  &:hover {
    color: $clr-text;
    text-decoration: none;

    &::before {
      transform: scale(1.2);
    }
  }

  &:active,
  &:focus,
  &:focus-visible {
      @include accessible-focus(solid, $clr-link, 2px, 2px);
  }
}
.tool-card__button-inner {
  position: relative;
  z-index: 1;
}
.tool-card.is-active .tool-card__button {
  width: 198px;
}
.tool-card__button-text {
  opacity: 0;
  transition: opacity 350ms cubic-bezier(0.785, 0.135, 0.150, 0.860);
  white-space: nowrap;
}
.tool-card__button-icon {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: opacity 350ms ease-in-out, transform 350ms ease-in-out;

  svg {
    height: 36px;
    transform: translateY(4px);
    width: 36px;
  }
}
.tool-card.is-active {
  .tool-card__button-text {
    opacity: 1;
  }
  .tool-card__button-icon {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
}

/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/

@media (max-width: 1190px) {
  .sell-with-confidence__col {
    @include flex-direction(column);
    align-items: flex-start;
  }
  .sell-with-confidence__logo {
    flex: 1 0;
  }
  .sell-with-confidence__logo-image {
    height: 100px;
  }
}

@media (max-width: 1080px) {
  .related-news {
    @include grid(1fr 1fr);
  }
}

@media (max-width: 1040px) {
  .sell-with-confidence {
    @include grid(1fr);
  }
  .sell-with-confidence__logo-image {
    height: 160px;
    width: auto;
  }
}

@media (max-width: 980px) {
  .footer__container {
    @include grid(repeat(2, 1fr));
  }
  .footer__col--explore {
    grid-column: 1/3;
  }
}

@media (max-width: 920px) {
  .masthead__intro {
    @include grid(1fr);
  }
  .masthead__intro__content {
    padding: $padding 0 0;
    text-align: center;
  }

  .tool-card__content {
    grid-column: 1/4;
  }
}

@media (max-width: 768px) {
  .tools__thumbnail {
    display: none;
  }
  .tools__content {
    grid-column: 1/3;
  }

  .tool-card {
    &:nth-child(3) {
      grid-column: 1/4;
    }
    &:nth-child(4) {
      grid-column: 4/7;
    }
    &:nth-child(5) {
      grid-column: 1/7;
    }
  }

  .related-news {
    display: block;
  }
  .related-news .feature {
    text-align: center;
  }
  .related-news__content {
    min-height: auto;
  }
  .related-news__carousel {
    grid-column: initial;
    margin-top: $gutter;
  }

  .copyright__inner {
    flex-direction: column;
  }
  .copyright__text {
    text-align: center;
  }
  
  .socials {
    justify-content: center;
  }
}

@media (max-width: 680px) {
  .section--tools {
    padding: $padding 0;

    &::before {
      display: none;
    }
  }

  .tools__background-image {
    display: none;
  }

  .tool-card {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      grid-column: 1/7;
    }

    &:nth-child(1) .tool-card__content,
    &:nth-child(2) .tool-card__content {
      gap: $gutter;
    }
  }
}

@media (max-width: 557px) {
  .page-card {
    min-height: 340px;
  }
  .page-card__thumbnail {
    grid-column: 3/4;
  }
  .page-card__thumbnail-image {
    transform: translateX(0);
    transform-origin: bottom right;
  }
  .page-card__content {
    height: 100%;
  }

  .tool-card__thumbnail {
    grid-column: 2/4;
  }
  .tool-card__thumbnail-image {
    transform: translateX(23%);
    transform-origin: initial;
  }
}

@media (max-width: 550px) {
  .footer__container {
    @include grid(1fr);
  }
  .footer__col--explore {
    grid-column: 1/2;
  }
  .footer__nav--split {
    column-count: 1;
  }
}

@media (max-width: 500px) {
  .page-card__title {
    max-width: 60%;
  }
  .page-card__content {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .page-portals {
    @include grid(1fr);
  }

  #cookie-notice {
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
  }
}