/*------------------------------------*\
    #MIXINS
\*------------------------------------*/
@mixin font-script {
  font-family: $font-script;
  font-weight: 700;
  font-style: normal;
}

@mixin grid($columns) {
  display: grid;
  grid-template-columns: $columns;
}

@mixin font-xxxl {
  // letter-spacing: -.025rem;
  font-size: clamp(2.33rem, calc(1.44rem + 4.44vw), 5.00rem);
  line-height: 1.1;
}

@mixin font-xxl {
  // letter-spacing: -.0125rem;
  font-size: clamp(1.94rem, calc(1.41rem + 2.65vw), 3.23rem);
  line-height: 1.1;
}

@mixin font-xl {
  // letter-spacing: -.0275rem;
  font-size: clamp(1.62rem, calc(1.33rem + 1.47vw), 2.50rem);
  line-height: 1.2;
}

@mixin font-large {
  // letter-spacing: -.0125rem;
  font-size: clamp(1.35rem, calc(1.21rem + 0.70vw), 1.77rem);
  line-height: 1.25;
}

@mixin font-medium {
  // letter-spacing: -.0125rem;
  font-size: clamp(1.13rem, calc(1.08rem + 0.21vw), 1.25rem);
  line-height: 1.4;
}

@mixin font-small {
  // letter-spacing: .025rem;
  font-size: clamp(0.88rem, calc(0.96rem + -0.09vw), 0.94rem);
}

@mixin standard-transition($element, $type, $time: 0.375s) {
  -webkit-transition: $element $type $time;
  -moz-transition: $element $type $time;
  transition: $element $type $time;
}

@mixin standard-gradient($from, $to, $deg) {
  background: $from;
  background: -moz-linear-gradient($deg, $from 0%, $to 100%);
  background: -webkit-linear-gradient($deg, $from 0%, $to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$from', endColorstr='$to', GradientType=0);

  @if $deg=="top" {
    background: linear-gradient(to bottom, $from 0%, $to 100%);
  }

  @if $deg=="-45deg" {
    background: linear-gradient(135deg, $from 0%, $to 100%);
  }

  @if $deg=="45deg" {
    background: linear-gradient(45deg, $from 0%, $to 100%);
  }

  @if $deg=="across" {
    background: linear-gradient(to right, $from 0%, $to 100%);
  }
}

@mixin font-caps() {
  text-transform: uppercase;
  font-family: $font-caps;
  font-weight: normal;
}

@mixin header-style() {
  font-family: $font-header;
}

@mixin vertical-center() {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin font-uppercase {
  font-weight: 400;
  text-transform: uppercase;
  font-family: $font-caps;
}

@mixin circle($unit) {
  width: $unit;
  height: $unit;
  border-radius: $unit;
}

@mixin flex-direction($dir: row) {
  display: flex;
  flex-direction: $dir;
}

@mixin standard-padding() {
  padding: 1.5rem;
  padding: clamp(1.5rem, 2vw, $gutter);
}

@mixin standard-borderradius() {
  border-radius: 2rem;
  border-radius: clamp(1rem, 2vw, 2rem);
}

$fadeInSize: 1rem;

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, $fadeInSize, 0);
    -ms-transform: translate3d(0, $fadeInSize, 0);
    transform: translate3d(0, $fadeInSize, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, $fadeInSize, 0);
    -ms-transform: translate3d(0, $fadeInSize, 0);
    transform: translate3d(0, $fadeInSize, 0)
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

@mixin fadeInUp($delay) {
  -webkit-animation: fadeInUp 1s both $delay;
  animation: fadeInUp 1s both $delay;
}

@mixin fixedHeaderClearing() {
  padding-top: 3rem;

  @media screen and (min-width: 671px) {
    padding-top: 8rem;
  }
}

@mixin link-underline($linkclr) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: .25rem;
  -webkit-text-decoration-color: $linkclr;
  text-decoration-color: $linkclr;
}

@mixin link-underline-animation($linkclr) {
  &:before {
    content: '';
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    -webkit-transition: all .25s;
    transition: all .25s;
    background: $linkclr;
    width: 0;
    z-index: 2;
  }

  &:hover:before {
    width: 100%;
  }
}

@mixin link-underline-animation2($linkclr) {

  &:before,
  &:after {
    content: '';
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    -webkit-transition: all .25s;
    transition: all .25s;
  }

  &:before {
    background: $linkclr;
    width: 0;
  }

  &:after {
    background: rgba($linkclr, 20%);
    width: 100%;
  }

  &:hover {
    color: $linkclr;
  }

  &:hover:before {
    width: 100%;
  }
}

@mixin focus-outline($style: solid, $clr: #fff, $width: 1px, $offset: .5rem) {
  outline-style: $style;
  outline-color: $clr;
  outline-width: $width;
  outline-offset: $offset;
}

@mixin curved-edge {
  border-radius: 1rem 1rem 0 0;
  position: relative;
  top: -1rem;
}

@mixin box-style($padding: 1.5rem) {
  background: #fff;
  border: 1px darken($clr-shade, 5%) solid;
  border-radius: $border-radius;
  padding: $padding;
  overflow: hidden;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@keyframes growDown {
  0% {
    transform: scaleY(0)
  }

  100% {
    transform: scaleY(1)
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@mixin section-spacing {
  padding-top: clamp(1.5rem, $spacing_vw, 3rem);
  padding-bottom: clamp(1.5rem, $spacing_vw, 3rem);
}

@mixin link-underline-standard {
  @include link-underline(rgba($clr-primary, .125));
  color: $clr-primary;

  &:hover {
    color: $clr-third;
    @include link-underline(rgba($clr-third, .125));
  }

  &:focus {
    @include link-underline(rgba($clr-third, 1));
  }
}

@mixin absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin accessible-focus($style: solid, $clr: #fff, $width: 1px, $offset: .5rem) {
  outline-style: $style;
  outline-color: $clr;
  outline-width: $width;
  outline-offset: $offset;
}

@mixin font-light-italic {
  font-weight: 300;
  font-style: italic;
  font-family: "Soho Gothic Light Italic", SohoGothicStd, 'Titillium Web', "Montserrat", Helvetica Neue, Helvetica, Arial, sans-serif;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@mixin invisible-overlay {
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}