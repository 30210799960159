@import 'variables';
@import 'mixins';

/*------------------------------------*\
    #CLAIM FORM SPECIFIC STYLES
\*------------------------------------*/

.form__group-heading {
    padding: $padding 0;
    text-align: center;
}
.form__group-title {
    @include font-large;
}
.form__group-text {
    margin: 1rem 0 0;
}

.form__fields {
    @include flex-direction(column);
    gap: 1.5rem;
}

.form__field-wrap {
    @include flex-direction(row);
    gap: 1rem;
}

.form__row {
    flex: 1 0;
}

.form__label,
.form__input {
    display: block;
    width: 100%;
}

.form__label {
    margin-bottom: 0.65rem;
    line-height: 1.1;
}
.form__label-title {
    display: block;
}
.form__label-subtitle {
    display: block;
    font-size: 0.75em;
}

.form__subsection {
    margin: $padding 0;

    .form__checkbox {
        margin-top: 1.5rem;
    }

    &--highlight {
        @include flex-direction(row);
        align-items: center;
        background-color: $clr-shade;
        padding: clamp(1rem, 3.5vw, 2rem);
        gap: clamp(0.5rem, 3.5vw, 1rem);
    }
}
.form__subsection__icon {
    svg {
        color: $clr-third;
        height: 50px;
        width: auto;
    }
}

.form__group-actions {
    @include flex-direction(row);
    border-top: solid 2px $clr-shade;
    gap: $gutter;
    justify-content: space-between;
    padding-top: 1.5rem;
}

/*------------------------------------*\
    #METALS INPUT FIELDS
\*------------------------------------*/

.metals {
    @include grid(repeat(4, 1fr));
    align-items: flex-start;
    gap: $gutter;
}

.metal__title {
    @include font-medium;
    font-family: $font-body;
    font-weight: $bold-weight;
    margin: 0;
    text-align: center;
}
.metal {
    @include flex-direction(column);
    gap: 0.5rem;
    justify-content: flex-start;
    // padding: 1.5rem;
    // background-color: darken($clr-shade, 5%);
    height: 100%;
}
.metal .form__row {
    @include flex-direction(row);
    // border-radius: $border-radius-large;
    flex-grow: 0;
}
.metal .form__label {
    // border-bottom-left-radius: $border-radius-large;
    // border-top-left-radius: $border-radius-large;
    display: block;
    flex: 70px 0;
    padding: 0.35em 0.5em;
    margin-bottom: 0;
    text-align: center;
}
.metal--gold .form__label {
    background: linear-gradient(45deg, lighten(gold, 5%), darken(gold, 10%));
}
.metal--silver .form__label {
    background: linear-gradient(45deg, lighten(silver, 20%), lighten(silver, 5%));
}
.metal--platinum .form__label {
    background: linear-gradient(45deg, lighten(silver, 15%), silver);
}
.metal--palladium .form__label {
    background: linear-gradient(45deg, lighten(silver, 20%), silver);
}
.metal .form__input {
    // border-bottom-left-radius: 0;
    // border-bottom-right-radius: $border-radius-large;
    // border-top-left-radius: 0;
    // border-top-right-radius: $border-radius-large;
    border-radius: 0;
    flex: 1 0;
    padding: 0.35rem 0.5rem;
}

/*------------------------------------*\
    #MEDIA QUERIES
\*------------------------------------*/

@media (max-width: 768px) {
    .metal .form__label {
        flex: 60px 0;
    }
    .metal .form__label-title {
        @include font-small;
    }
    .metal__title {
        @include font-small;
    }
}

@media (max-width: 640px) {
    .metals {
        @include grid(1fr);
    }
}

@media (max-width: 375px) {
    .form__field-wrap {
        @include flex-direction(column);
    }
}