/*------------------------------------*\
    #FAQS
\*------------------------------------*/

.section--faqs {
    padding: $padding-large 0;
  
    .feature--crossline .feature__keyword span {
      background-color: $clr-white;
    }
  }
  
  .faqs {
    @include flex-direction(column);
    gap: 1rem;
  }
  
  .faq__question {
    @include flex-direction(row);
    align-items: center;
    background-color: $clr-shade;
    border: solid 1px $clr-shade;
    border-radius: $border-radius;
    font-weight: $bold-weight;
    line-height: 1;
    gap: 1rem;
    padding: 1rem;
    text-align: left;
    width: 100%;
    transition:
      background-color 350ms ease-in-out,
      border-color 350ms ease-in-out;
  
    &:hover {
      background-color: darken($clr-shade, 10%);
      border-color: darken($clr-shade, 10%);
    }
  }
  .faq--faqs .faq__question {
    background-color: $clr-white;
    border-color: $clr-white;

    &:hover {
      background-color: darken($clr-white, 5%);
      border-color: darken($clr-white, 5%);
    }
  }
  .faq__question-icon {
    @include flex-direction(row);
    align-items: center;
    background-color: $clr-link;
    border-radius: 100%;
    color: $clr-secondary;
    height: 26px;
    justify-content: center;
    width: 26px;
    min-width: 26px;
    min-height: 26px;

    svg {
        transform: rotate(-90deg);
    }
  }
  .faq__answer {
    background-color: $clr-white;
    border: {
      left: solid 1px $clr-shade;
      bottom: solid 1px $clr-shade;
      right: solid 1px $clr-shade;
      radius: 0 0 $border-radius $border-radius;
    }
    display: none;
    padding: 1rem 3.65rem;
  }
  .faq--faqs .faq__answer {
    border-color: $clr-white;
  }
  .faq--active {
    .faq__question {
        border-radius: $border-radius $border-radius 0 0;
    }
    .faq__answer {
        display: block;
    }
    .faq__question-icon {
        transform: rotate(90deg);
      }
  }

@media (max-width: 680px) {
  .faq__answer {
    padding: 1rem;
  }
}